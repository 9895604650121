@import "../../assets/scss/variables";

.confirm-text {
  text-align: center;
}

.buttons-holder {
  text-align: center;
  & button:first-child {
    margin-right: 10px;
  }
}
