@import '../../../assets/scss/variables.scss';

.church-report-modal-comment {
  &-container {
    z-index: 1000;
    width: 280px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid gray;
    background-color: $white;
  }

  &-icon {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    position: relative;

    &-fill {
      background-image: url('../../../assets/img/comment.svg');
    }

    &-not-fill {
      background-image: url('../../../assets/img/no-comment.svg');
    }

    &-included {
      background-image: url('../../../assets/img/comment.svg');
    }
  }
}