@import '../../../assets/scss/variables';

.church-table {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  &-content {
  }
}

.remove-list-styles {
  list-style-type: none !important;
}
