@import "../../assets/scss/variables";

.completed-progress-bar {
  &-title {width: 100%; text-align: center; float: left; font-size: 0.8rem; margin-bottom: 3px;}
  &-field {width: 100%; height: 18px; background: transparent; border: 1px solid $darkest-gray;
    overflow: hidden; border-radius: 18px;
  }
  &-slider {height: 100%; border-radius: 18px; color: $white; background: $red;
    display: flex; align-items: center; justify-content: center; font-size: $smaller;
    line-height: 18px;
    &-green {background: $green;}
  }
}
