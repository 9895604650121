@import '../../assets/scss/variables';

.church-list {
  display: grid;
  grid-gap: 10px;
  height: 100%;
  max-height: 100%;
  grid-template-areas:
    'filters info'
    'content info';
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 350px;
  &-filters {
    grid-area: filters;
  }
  &-info {
    grid-area: info;
  }
  &-content {
    grid-area: content;
  }
}
