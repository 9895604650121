@import "../../assets/scss/variables";

.status {padding: 5px 20px; color: $white; border-radius: 18px; float: left;
  &-inquiry {background: $purple;}
  &-intake {background: $pink;}
  &-survey {background: $green;}
  &-coaching {background: $green-blue;}

  &-pre {background: $gray;}
  &-active {background: $green;}
  &-extended {background: $error;}
  &-closed {background: $red;}
}
