@import "../../assets/scss/variables";

.survey-view-modal {
  &-title {margin: 1rem 0 1.5rem;}
  &-content {height: 95%; width: 90%; max-width: 700px; overflow: hidden;
    .modal {display: flex; flex-direction: column; height: 100%; box-sizing: border-box;
      &-content {flex: 1 1 auto; padding: 0;}
    }
  }
}
