@import "../../assets/scss/variables";

.survey-row {color: #333E48; margin-bottom: 15px;
  &-title{font-size: 16px; font-weight: 500;}
  &-question{font-size: 15px; font-weight: 400; padding: 0.4rem 0;}
  &-answers{font-size: 13px;}
  &-answer{border-top: 1px solid #edeeee; padding: 0.4rem 0;}
  &-label{display: inline-block; width: 30%;
    & ~ .survey-row-text {text-align: end;}
  }
  &-text{display: inline-block; width: 70%;
    &:nth-child(n+3) {width: 100%;}
  }
}

