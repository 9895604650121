.comment-action {
  position: absolute;
  right: 0;
  width: 20px;
  top: 0;

  &-button {
    background-color: transparent;
    border: none;
    outline: none;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}