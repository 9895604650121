@import '../../assets/scss/variables';

.page-layout {
  background-color: $page-background;
  min-height: 100vh;
  position: relative;
  width: 100%;
  &-auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      img {
        width: 280px;
      }
    }
    &-content {
      width: 100%;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      .form {
        padding: 40px 60px 80px;
        background: $white;
        border-radius: 20px;
        width: 280px;
        box-shadow: 0 2px 500px rgba(197, 197, 197, 0.14);
        @media (max-width: $mobile) {
          padding: 40px 20px 60px;
          min-width: 300px;
        }

        &-response {
          color: $green;
          margin-top: 2.1rem;
          text-align: center;
        }

        h1 {
          font-weight: 200;
          text-align: center;
          margin-bottom: 1.2rem;
        }
        .form-buttons {
          text-align: center;
          .button {
            margin-bottom: 1.7rem;
          }
        }
      }
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    height: 100vh;
    &-content {
      z-index: 1;
      width: 100%;
      padding: 30px 0 0;
      flex: 1 1 100%;
      height: 100px;
    }
  }
}
