.comment-edit-form {
  &-input {
    width: 100%;
    resize: none;
    box-sizing: border-box;
    line-height: 18px;
    height: 54px;
    font-weight: 200;
  }

  &-submit {
    background-image: url('../../../../../assets/img/check.svg');
  }

  &-cancel {
    background-image: url('../../../../../assets/img/remove.svg');
  }
}