@import '../../../assets/scss/variables';

.church-list-filters-modal {
  &-content {
    width: 45%;
    .form-group-checkboxes .form-control-container {
      grid-template-columns: 100%;
    }

    &-completed {
      margin-bottom: 3rem;
      .form-group {
        margin-top: 1rem;
      }
    }

    button {
      min-width: 200px;
      margin: 0;
    }

    .animation-fade-in {
      display: inline-block;
      button {
        margin-right: 1rem;
        min-width: 150px;
      }
    }
  }
}
