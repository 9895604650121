@import '../../../assets/scss/variables';

.church-filters-form {
  .form-buttons,
  .form-control-container {
    margin-bottom: 0;
    margin-top: 0;
  }

  .col {
    &:first-child {
      padding: 0 0.2rem 0 0.5rem;
    }
    &:last-child {
      padding: 0 0.5rem 0 0.2rem;
    }
  }
  @media (min-width: $smalldesktop) {
    .form-group {
      margin-top: 0;
    }
  }
}
