@import "../../../assets/scss/variables.scss";

.church-report-modal-panel {
  height: 100%;
  overflow: auto;

  &-item {
    cursor: pointer;
    text-align: center;
    margin: 0;
    padding: 14px 0;
    border-bottom: 1px solid $lightest-gray;
    
    &:last-child {
      border-bottom: none;
    }

    &.selected {
      background-color: $blue-gray;
      color: $white;
    }
  }
}