.animation {
  &-fade-in-down {opacity: 0; max-height: 0; transform-origin: top; pointer-events: none;
    transition: max-height 0.25s, opacity 0.4s, transform 0.4s; transform: translateY(-60px);

    &.is-animating {transform: none; opacity: 1; max-height: 800px; pointer-events: auto;
      transition: max-height 0.25s, opacity 0.4s, transform 0.4s;
    }
  }

  &-fade-in {opacity: 0; transition: opacity 0.4s;
    &.is-animating {opacity: 1; transition: opacity 0.4s;}
  }
}
