@import '../../assets/scss/variables';

.app-header {top: 0; position: sticky; z-index: 2; padding: 10px 0;  background: $white;
  box-shadow: 0 4px 4px rgba(43, 45, 55, 0.02);
  &-content {display: flex; align-items: center;
    .button {margin: 0 10px 0;}
    .dropdown-content {right: -8px;}
  }
  &-logo {width: 100px; margin-right: auto;}
  &-user-name {margin: 0 0 0 12px; line-height: 18px; letter-spacing: .4px;}
  &-lang {line-height: 30px; width: 80px; display: block; white-space: nowrap;
    .icon {position: relative; margin-right: 10px;}
  }
  .dropdown-content {
    .app-header-lang {position: relative; width: 100px; left: -7px;}
  }
}
