@import "../../assets/scss/variables";

.row {margin: 0 -.5rem; display: flex; flex-wrap: wrap;
  &-no-indent {margin: 0;
    & > .col {padding: 0;}
  }
  &-reverse {flex-direction: row-reverse;}
}

.col {padding: 0 .5rem; box-sizing: border-box;
  &-17 {width: 17%; flex: 0 0 17%;}
  &-20 {width: 20%; flex: 0 0 20%;}
  &-25 {width: 25%; flex: 0 0 25%;}
  &-30 {width: 30%; flex: 0 0 30%;}
  &-33 {width: 33%; flex: 0 0 33%;}
  &-40 {width: 40%; flex: 0 0 40%;}
  &-45 {width: 45%; flex: 0 0 45%;}
  &-50 {width: 50%; flex: 0 0 50%;}
  &-55 {width: 55%; flex: 0 0 55%;}
  &-60 {width: 60%; flex: 0 0 60%;}
  &-66 {width: 66%; flex: 0 0 66%;}
  &-70 {width: 75%; flex: 0 0 70%;}
  &-75 {width: 75%; flex: 0 0 75%;}
  &-80 {width: 80%; flex: 0 0 80%;}

  @media (max-width: $tablet) {
    &-tablet-17 {width: 17%; flex: 0 0 17%;}
    &-tablet-25 {width: 25%; flex: 0 0 25%;}
    &-tablet-33 {width: 33%; flex: 0 0 33%;}
    &-tablet-50 {width: 50%; flex: 0 0 50%;}
    &-tablet-66 {width: 66%; flex: 0 0 66%;}
    &-tablet-75 {width: 75%; flex: 0 0 75%;}
    &-tablet-100 {width: 100%; flex: 0 0 100%;}
  }

  @media (max-width: $mobile) {width: 100%; flex: 0 0 100%; order: 0;
    &-mobile-17 {width: 17%; flex: 0 0 17%;}
    &-mobile-25 {width: 25%; flex: 0 0 25%;}
    &-mobile-33 {width: 33%; flex: 0 0 33%;}
    &-mobile-50 {width: 50%; flex: 0 0 50%;}
    &-mobile-66 {width: 66%; flex: 0 0 66%;}
    &-mobile-75 {width: 75%; flex: 0 0 75%;}
  }
}
