@import '../../../../assets/scss/variables.scss';

.comment-box {
  &-container {
    z-index: 1000;
    width: 280px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid gray;
    background-color: $white;
  }

  &-form {
    &-body-input {
      box-sizing: border-box;
      width: 100%;
      height: 150px;
      resize: none;
      padding: 10px;
    }

    &-button-holder {
      text-align: end;
    }
  }

  &-edit {
    background-image: url('../../../../assets/img/edit.svg');
  }

  &-delete {
    background-image: url('../../../../assets/img/trash.svg');
  }

  &-list {
    list-style-type: none;
    padding: 0;
    max-height: 211px;
    overflow: auto;

    &-item {
      margin-top: 10px;
      line-height: 18px;
      min-height: 54px;
      overflow: hidden;
      padding-right: 25px;
      position: relative;
      white-space: break-spaces;
    }
  }
}