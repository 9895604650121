@import '../../assets/scss/variables';

@mixin placeholder-style {
  margin: 0 !important;
  color: $light-gray !important;
}

.form {
  &-section {margin-bottom: 3rem;}
  &-title {margin-bottom: 1rem;}
  &-group {margin-bottom: 1rem; position: relative;
    &-checkbox {
      .form-control-container {margin-top: 15px;}
    }
    &-checkboxes {
      .form-control-container {display: grid; grid-template-columns: 50% 50%; grid-gap: 0 15px;
        padding: 20px 0; margin: 0;
      }
    }
  }
  &-control {transition: all 0.3s !important; text-overflow: ellipsis; box-sizing: border-box !important; width: 100%;
    padding: var(--control-h-padding) var(--control-v-padding); background: $white; border-radius: 36px !important;
    border: 1px solid $lightest-gray !important; cursor: text; font-size: var(--control-font-size); font-weight: 200;

    &-container {position: relative; margin-top: .7rem;}
    &-icon {position: absolute; padding: 0 !important; top: 50%; transform: translate(0, -50%);
      z-index: 1;
      &-left {left: 15px;
        & ~ .form-control {padding-left: 3.3rem;}
      }
      &-right {right: 15px;
        & ~ .form-control {padding-right: 65px;}
      }
    }

    &:focus, &-focus {border-color: rgba($primary, .5) !important;}
    &::placeholder, &-select-placeholder::placeholder {
      @include placeholder-style;
    }
    &-error {border-color: $error !important;}

    &-select {cursor: pointer !important; box-shadow: none !important;
      &-value {padding: 0 !important;}
      &-dropdown {background: $white; border: 1px solid $lightest-gray; border-radius: 25px !important;
        box-shadow: none !important; z-index: 99 !important; overflow: hidden;
        &-list {padding: 0 !important; color: $primary; overflow: auto;
          &-item {background: $white !important; border-radius: 26px; padding: 13px 25px !important;
            font-weight: 200 !important; cursor: pointer !important;
            &:hover {background-color: $primary !important; color: $white !important;}
            &.active {font-weight: 600 !important; color: $primary;}
          }
        }
      }
    }

    &-date-picker {@extend .form-control-select-dropdown; position: absolute;
      top: 100%; transform: translateY(5px); right: 0; margin-bottom: 20px;
      z-index: 99;
      &-container {position: relative;}
      &-input {padding-right: 2.2rem; padding-left: 3.8rem;}

      .DayPicker {
        &-Day {color: $light-blue;
          &:hover {background-color: rgba($green, .4) !important;}
          &--selected {background-color: $green !important;}
          &--today {color: $primary;}
          &--outside {pointer-events: none;}
        }
        &-Weekday {color: $light-gray;}
      }

    }

    &-small {--control-h-padding: .89rem; --control-v-padding: 1.3rem; --control-font-size: $small;}
    &-normal {--control-h-padding: 1rem; --control-v-padding: 1.3rem; --control-font-size: $normal;}
  }
  &-checkbox {position: relative; display: flex; align-items: center; cursor: pointer;
    input {opacity: 0; width: 0; height: 0; position: absolute;
      &:checked ~ .form-checkbox-icon::after {opacity: 1; transform: scale(1);}
    }

    &-icon {width: 22px; height: 22px; min-width: 22px; border-radius: 2px; background: $white;
      border: $lightest-gray 1px solid; transition: all 0.3s; display: inline-block; vertical-align: middle;
      position: relative;
      &::after {width: 18px; height: 18px; position: absolute; top: -1px; left: -1px;
        transition: all 0.3s; transform: scale(0); content: '';
        background: url('../../assets/img/icons.svg') -63px -43px; opacity: 0;
      }
    }
    &-title {margin-left: 15px;}

    &:hover .form-checkbox-icon {border-color: $lightest-blue;}
  }

  &-label {left: 0; top: 0; color: $light-blue; white-space: nowrap;}
  &-error {color: $error; font-size: $smaller; margin-top: .3rem;}
  &-buttons {margin-top: 1.5rem; text-align: var(--buttons-align);
    &-left {--buttons-align: left;}
    &-right {--buttons-align: right;}
    &-center {--buttons-align: center;}

    button {margin-right: 1rem; margin-bottom: 1rem;
      &:last-of-type {margin-right: 0;}
      &:first-of-type {margin-left: 0;}
      @media(max-width: $mobile) {width: 100%;}
    }
  }
  &-disable {opacity: 0.4; pointer-events: none;}
}

.form-control::placeholder, .form-control-select-placeholder::placeholder {
  @include placeholder-style;
}
