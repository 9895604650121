.element-box-holder {
  width: 100%;
  height: calc(100% - 91px);
  position: relative;

  .element-box {
    padding: 0 40px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}