@import '../../../assets/scss/variables';

.church-pagination {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
}

/* Container for the entire pagination component */
.church-pagination .pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: center;
  gap: 10px;
}

/* Style for each page number and navigation buttons (Previous, Next) */
.church-pagination .pagination li a {
  background-color: $blue-gray;
  display: block;
  padding: 0.375rem 0.75rem;
  color: $white;
  border-radius: $border-radius;
  font-size: $small;
  text-align: center;
  line-height: 1.5;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover,
  &:focus {
    background-color: darken($blue-gray, 5%);
  }
}

/* Style for the active page */
.church-pagination .pagination li.active a {
  background-color: $primary;
  color: $white;
}

.church-pagination .pagination li.disabled a {
  cursor: not-allowed;
  opacity: 0.65;
}
