@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 200;
  src: local('CircularStd-Book'), local('Circular Std Book'),
  url('../fonts/CircularStd-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  src: local('CircularStd-Medium'), local('Circular Std Medium'),
  url('../fonts/CircularStd-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 600;
  src: local('CircularStd-Bold'), local('Circular Std Bold'),
  url('../fonts/CircularStd-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 800;
  src: local('CircularStd-Black'), local('Circular Std Black'),
  url('../fonts/CircularStd-Black.ttf') format('truetype');
}
