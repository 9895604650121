@import '../../assets/scss/variables';

.table {
  width: 100%;
  @media (max-width: $mobile) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    width: calc(100% + 3rem);
  }

  &-row {
    border: 1px solid $lightest-gray;
    width: 100%;
    display: block;
    position: relative;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: $small;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  &-cell {
    float: left;
    min-height: 1px;
    padding: 13px 5px;
    box-sizing: border-box;
    text-align: var(--table-cell-align);
    &:first-of-type {
      padding-left: 1rem;
    }
    &:last-of-type {
      padding-right: 1rem;
    }

    @media (max-width: $mobile) {
      float: none;
      width: 100% !important;
      display: flex;
      flex-wrap: wrap;
      padding: 1rem 1.5rem;
      &:first-of-type {
        padding-left: 1.5rem;
      }
      &:last-of-type {
        padding-right: 1.5rem;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: var(--table-cell-align);
      .icon {
        margin-left: 5px;
      }
      .form-checkbox {
        justify-content: var(--table-cell-align);
      }
    }

    &.is-clickable {
      cursor: pointer;
      &:hover {
        background: $light-gray;
      }
    }
    &-align-right {
      --table-cell-align: right;
    }
    &-align-left {
      --table-cell-align: left;
    }
    &-align-center {
      --table-cell-align: center;
    }
  }

  &-head {
    margin-bottom: 3px;
    & .table-row {
      font-size: $normal;
      border-color: rgba(0, 122, 255, 0.1);
      display: table;
      background: rgba(0, 122, 255, 0.1);
    }
    & .table-cell {
      float: initial;
      display: table-cell;
      padding: 9px 5px;
      vertical-align: middle;
      font-weight: 400;
      &:first-of-type {
        padding-left: 1rem;
      }
      &:last-of-type {
        padding-right: 1rem;
      }

      &.is-clickable {
        &:hover {
          background: rgba(0, 122, 255, 0.2);
        }
      }
    }

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    &:hover,
    &-selected {
      background: $lightest-gray-hover;
      border-color: $lightest-gray-hover;
    }
  }

  &-mobile-label {
    color: $gray;
    display: none;
    position: relative;
    text-align: left;
    @media (max-width: $mobile) {
      display: block;
      flex: 1;
    }
  }
  &-mobile-content {
    position: relative;
    text-align: left;
    width: 100%;
    @media (max-width: $mobile) {
      flex: 1;
      width: auto;
    }
  }
}
