.review-box-holder {
  width: 100%;
  height: calc(100% - 91px);
  position: relative;

  .review-box {
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 1300px) {
      left: 0;
      transform: translateY(-50%);
    }
  }
}