@import "../../../assets/scss/styles";

.church-details {
  &-header {display: flex; justify-content: space-between;
    margin-bottom: 20px;
  }
  &-content {}
  &-title {flex: 1 0 auto;}
  &-actions {text-align: end; flex: 0 1 auto;
    .button:not(:last-of-type) {margin-bottom: 10px;}
  }
}

.close-survey-button {
  color: red;
}
