@import '../../assets/scss/variables';

.icon {width: 2.3rem; height: 2.3rem; display: inline-block; vertical-align: top;
  background-image: url('../../assets/img/icons.svg'); background-position: var(--bg-position);
  transform: scale(.8); transition: all .3s;
  &-mask {mask-image: url('../../assets/img/icons.svg'); mask-position: var(--bg-position);
    &-black {background: $black;}
    &-light-gray {background: $light-gray;}
    &-blue-gray {background: $blue-gray;}
    &-primary {background: $primary;}
    &-white {background: $white;}
  }

  &-lock {--bg-position: -.2rem -.2rem;}
  &-user {--bg-position: -2.85rem -.2rem;}
  &-search {--bg-position: -8.2rem -.17rem;}
  &-filter {--bg-position: -10.9rem -.15rem;}
  &-calendar {--bg-position: -5.5rem -.2rem;}
  &-arrow-down {width: 1rem; height: 1rem; --bg-position: -2.48rem 1.5rem;}
  &-arrow-up {@extend .icon-arrow-down; transform: scale(.8) rotate(180deg);}
  &-arrow-left {@extend .icon-arrow-down; transform: scale(.8) rotate(90deg);}
  &-arrow-right {@extend .icon-arrow-down; transform: scale(.8) rotate(-90deg);}

  &-en {height: 30px; width: 30px; background-image: url('../../assets/img/flags.svg'); --bg-position: 0 0;
    transform: scale(1.2);}
  &-es {height: 30px; width: 30px;  background-image: url('../../assets/img/flags.svg'); --bg-position: -180px -60px;
    transform: scale(1.2);}
}
