@import "../../assets/scss/variables";

.link {text-decoration: none; position: relative; transition: all 0.5s; color: var(--link-color);
  cursor: pointer;
  &-underline {position: absolute; width: 100%; bottom: -5px; border-bottom: 1.3px solid;
    transition: all 0.4s; color: var(--link-underline-color);
  }

  &:hover {color: var(--link-hover-color);
    .link-underline {margin-left: -5px; width: calc(100% + 10px); color: var(--link-underline-hover-color);}
  }

  &-blue {--link-color: #{$blue}; --link-underline-color: #{$blue};
    --link-hover-color: #{$blue}; --link-underline-hover-color: #{$blue};
  }
  &-red {--link-color: #{$red}; --link-underline-color: #{$red};
    --link-hover-color: #{$red}; --link-underline-hover-color: #{$red};
  }
  &-light-gray {--link-color: #{$light-gray}; --link-underline-color: #{$dark-blue};
    --link-hover-color: #{$dark-blue};
  }
}
