@import '../../assets/scss/variables';
@import "src/components/Icon/Icon.scss";

@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.button {display: inline-flex; border: none; outline: none; box-sizing: border-box; color: $white;
  letter-spacing: .7px; border-radius: 33px; min-width: 100px; padding: var(--button-h-padding) var(--button-v-padding);
  line-height: 21px; text-decoration: none; cursor: pointer; font-size: var(--button-font-size);
  font-weight: var(--button-font-weight); text-align: center; white-space: nowrap; transition: all 0.6s; position: relative;
  justify-content: center; align-items: center;

  &-filled {background: var(--button-color);
    .icon {background: $white;}

    &:hover {background: var(--button-color-hover);}
    &:active {background: var(--button-color-hover); box-shadow: 0 4px 4px rgba(var(--button-color), 0.2)}
  }
  &-outline {border: 1px solid var(--button-color); background: transparent; color: var(--button-content-color);
    .icon {background: var(--button-content-color);}

    &:hover {border-color: var(--button-color-hover); color: var(--button-color-hover);
      .icon {background: var(--button-color-hover);}
    }
    &:active {border-color: var(--button-color-hover); color: var(--button-color-hover);
      .icon {background: var(--button-color-hover);}
    }
  }

  &-icon {margin-right: .5rem;
    .icon {@extend .icon-mask; transition: all 0.6s;}
  }

  &-blue-gray {--button-color: #{$blue-gray}; --button-color-hover: #{$blue-gray-hover}; --button-content-color: #{$blue-gray};}
  &-lightest-grey {--button-color: #{$lightest-gray}; --button-color-hover: #{$primary}; --button-content-color: #{$light-gray};}
  &-green {--button-color: #{$green}; --button-color-hover: #{$green-hover}; --button-content-color: #{$green};}
  &-red {--button-color: #{$red}; --button-color-hover: #{$error}; --button-content-color: #{$red};}

  &-block {width: 100%;}
  &-disabled {background: $gray; border-color: $gray; color: $white;
    box-shadow: none; pointer-events: none; cursor: default;
  }
  &-loading {@extend :active; pointer-events: none; opacity: 0.8; position: relative; color: transparent;
    &::after {position: absolute; width: 1rem; height: 1rem; border: $white 0.2rem solid; border-top-color: transparent;
      animation: spin 1s linear infinite; content: ""; top: 0; left: 0; bottom: 0; right: 0; margin: auto;
      border-radius: 50%; pointer-events: none;
    }
  }
  &-normal {--button-h-padding: .9rem; --button-v-padding: 2rem; --button-font-size: #{$normal}; --button-font-weight: 400;}
  &-small {--button-h-padding: .9rem; --button-v-padding: 2rem; --button-font-size: #{$small}; --button-font-weight: 200;}

  &-with-icon {
    &.button-small {--button-h-padding: .4rem;}
  }
}
