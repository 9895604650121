/* Fonts */
$font: Circular Std, Arial, sans-serif;

/* Colors */
$primary: #242e42;
$page-background: #edf3f9;
$white: #ffffff;
$black: #000000;
$black-overlay: #090909;
$dark-blue: #055399;
$blue: #1c72cf;
$lightest-blue: #007aff;
$darkest-blue: #202438;
$light-blue: #405159;
$lightest-gray: #ececec;
$lightest-gray-hover: #eceef0;
$error: #e86161;
$gray: #90979b;
$darkest-gray: #8c8c8c;
$light-gray: #95a2ae;
$blue-gray: #758b97;
$blue-gray-hover: #6c7e88;

$green: #10eaa7;
$green-hover: #10eaa7;
$red: #f7614b;
$green-blue: #4bdbf7;
$pink: #ea1084;
$purple: #782ef8;

/* Font sizes */
$smallest: 0.75rem;
$smaller: 0.8rem;
$small: 0.9rem;
$normal: 1rem;
$larger: 1.2rem;
$large: 1.3rem;
$title: 1.4rem;

/* Responsiveness breakpoints */
$smalldesktop: 1279px;
$tablet: 1023px;
$mobile: 767px;

$border-radius: 15px;
