@import "variables";
@import "fonts";

html, body {width: 100%; height: 100%; font-size: 15px; margin: 0; padding: 0;
  line-height: 1.2; font-weight: 200; font-family: $font; color: $darkest-blue;
  @media(max-width: $mobile) {font-size: 13px}
}

h1, h2, h3 {margin: 0; padding: 0; font-family: $font; font-weight: 600;}
h1 {font-size: $title; line-height: 1.4;}
h2 {font-size: $larger;}
h3 {font-size: $normal;}

a, button, input, select, textarea {outline: none; font-family: $font;}
