.language-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: #0056b3;
  color: white;
  padding: 10px 5px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.dropdown-button:hover {
  background-color: #003c82;
}

.dropdown-button .arrow {
  margin-left: 8px;
  font-size: 0.8em;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  list-style: none;
  padding: 0;
  margin: 4px 0 0 0;
  z-index: 1000;
  width: 100%;
}

.dropdown-menu li {
  padding: 10px 16px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.2s ease;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown-menu li + li {
  border-top: 1px solid #e6e6e6;
}
