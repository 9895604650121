@import "../../assets/scss/variables";

.church-info-modal {
  &-content {margin: 20px auto auto !important;
    .form-group {
      &-survey-close-date {margin-top: 1.86rem;}
    }

    button {margin: 0; min-width: 200px;}

    .row {position: relative; z-index: 2;
      &:last-of-type {z-index: auto;}
    }
  }
}
