@import "../../assets/scss/variables";

.church-report-modal {
  &-content {
    margin: 20px auto auto !important;
    width: 90%;
    height: 90%;
    .modal {
      height: calc(100% - 80px);
      &-content {
        height: calc(100% - 58px);
      }
    }
    .form-group {
      &-survey-close-date {
        margin-top: 1.86rem;
      }
    }

    button {
      margin: 0; min-width: 200px;
    }

    .row {
      height: 100%;
      .col {
        height: 100%;
      }
    }
  }

  &-grid {
    height: 100%;
    .row {
      height: 100%;
    }
  }

  &-box {
    height: 100%;
    position: relative;
  }
}

.panel-holder {
  height: 100%;
  border-right: 1px solid $lightest-gray;
}

.report-holder {
  height: 100%;
  margin: 0 auto;
  padding: 0 40px;
  box-sizing: border-box;
  position: relative;
  overflow: auto;

  &-title {
    margin: 0;
    font-size: 36px;
    font-weight: bold;
  }

  &-description {
    margin-block: 5px;
    font-size: 14px;
    line-height: 19px;
    height: 38px;
    overflow-y: hidden;
  }
}

.comment-holder {
  position: absolute;
  top: 0;
  right: -25px;
  text-align: end;
}
