@import "../../assets/scss/variables";

.dropdown {position: relative; cursor: pointer;
  &-trigger {padding: 5px 25px 5px 0; display: flex;
    justify-content: flex-end; align-items: center; line-height: 15px;
  }
  &-content {position: absolute; z-index: 10; top: 100%; left: 0; min-width: 60%;
    background: $white; padding: 0.3rem 0; border-radius: 10px; cursor: default;
    box-shadow: 0 5px 40px rgba(0, 68, 106, 0.2); pointer-events: none; transform: translateY(-5px);
    transform-origin: 0 0; opacity: 0; transition: all 0.4s; box-sizing: border-box; font-size: $smaller;

    .open & {margin-top: 0; transform: none; opacity: 1; top: 100%; pointer-events: auto;}

    &:before {position: absolute; content: ""; top: -19px; left: 30px;
      border: transparent 10px solid; border-bottom-color: $white;
    }

    &-left {left: auto; right: 0;
      &:before {left: 30px;}
    }
    &-right {right: 0; left: auto; transform-origin: 100% 0;
      &:before {right: 30px; left: initial;}
    }

    &-item {display: block; padding: 10px 20px; cursor: pointer; transition: background-color .2s ease;
      text-align: left; color: $black; box-sizing: border-box;
      &:hover {background-color: rgba($blue, 0.2);}
    }
  }
}
