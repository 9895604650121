@import "../../assets/scss/variables";

.modal {padding: 40px 45px;position: relative;
  &-close {position: absolute; top: 30px; right: 30px; cursor: pointer; z-index: 1;
    opacity: 0.5; transition: 0.3s; height: 16px; width: 16px;
    &:hover {opacity: 1;}
    &:before, &:after {position: absolute; content: ' '; height: 20px; width: 3px;
      background-color: #333; left: 6px; top: -2px;}
    &:before {transform: rotate(45deg);}
    &:after {transform: rotate(-45deg);}
  }
  &-header {font-size: $large;}
  &-content {padding-top: 35px;}
}

.popup {
  &-content {border-radius: 20px; width: 44%;}
  &-overlay {background: rgba($black-overlay, 0.6); overflow: scroll;}
}
