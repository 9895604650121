.progress-holder {
  width: 100%;
  margin: 0 auto;
}

.error-message {
  color: red;
  white-space: break-spaces;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin-right: 5px;
}
