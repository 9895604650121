.demographics-card {
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  width: 25%;

  @media (min-width: 1260px) {
    &:nth-child(4n) {
      border-right: none;
    }
  
    &:nth-child(n+5) {
      border-bottom: none;
    }
  }

  @media (min-width: 961px) and (max-width: 1260px) {
    width: 33%;

    &:nth-child(3n) {
      border-right: none;
    }

    &:nth-child(n+7) {
      border-bottom: none;
    }
  }

  @media (max-width: 960px) {
    width: 50%;

    &:nth-child(2n) {
      border-right: none;
    }

    &:nth-child(n+7) {
      border-bottom: none;
    }
  }
}